/**
 * 需要从主应用里面获取并且需要监听变更的字段
 */
import { createAction, handleActions } from 'redux-actions'

const SET_BASE_INFO = 'SET_BASE_INFO'

export const setBaseInfo = createAction(SET_BASE_INFO)

export default handleActions(
  {
    [SET_BASE_INFO]: (state, action) => ({...state, ...action.payload}),
  },
  {},
)
