import { createStore, compose, applyMiddleware, } from 'redux'
import reduxPromiseMiddleware from 'redux-promise-middleware'
import actionThunkMiddleware from './middleware/action-thunk'
import reducer from 'ROOT/reducer'

const enhancer = compose(
  applyMiddleware(reduxPromiseMiddleware(), actionThunkMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : x => x,
)

export default createStore(reducer, enhancer)
