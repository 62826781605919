import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { setBaseInfo } from 'ROOT/reducer/base-info'
import { Loading, globalStore, Sidebar, RootPath } from '@xm/entadmin-main'
import config from 'ROOT/router/config'
import Frame from './frame'

const { Context, Consumer } = RootPath

@connect(null, dispatch => ({
  actions: bindActionCreators(
    {
      setBaseInfo,
    },
    dispatch,
  ),
}))
@Consumer(Context)
class App extends Component {
  state = {
    loading: true,
    selectedKeys: [],
  }

  componentDidMount() {
    const {
      actions: { setBaseInfo },
    } = this.props

    // 获取orgId，并存入到redux中，方便后续其他组件监听变化
    globalStore.getAsyncs(['orgId', 'uid', 'name'], (isError, result) => {
      if (isError) return

      setBaseInfo(result)

      this.getSelecteKeys(this.props)
      this.setState({
        loading: false,
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    this.getSelecteKeys(nextProps)
  }

  getSelecteKeys(props) {
    const { getPath, context } = props

    const pathname = _.get(props, 'location.pathname')
    const defaultPath = _.without(pathname.split('/'), '')[context ? 1 : 0] || config.filter(x => x.isIndex)[0]
    const defaultSelectedKeys = config.filter(x => x.path === defaultPath).map(x => x.path)

    this.menuList = config.map(x => ({
      key: x.path,
      title: x.name,
      link: getPath(x.path),
      isHash: true,
    }))

    this.setState({
      selectedKeys: defaultSelectedKeys,
    })
  }

  render() {
    const { children } = this.props
    const { loading, selectedKeys } = this.state

    if (loading) return <Loading />

    return (
      <div className="fullscreen relative flex">
        <Sidebar className="h-100" appTitle="活动管理" menus={this.menuList} selectedKeys={selectedKeys} />
        <Frame>{children}</Frame>
      </div>
    )
  }
}

export default App
