import React from 'react'
import { Provider, } from 'react-redux'
import { HashRouter, } from 'react-router-dom'
import { ConfigProvider, } from 'antd'
import { zhCN, RootPath}  from '@xm/entadmin-main'
import store from 'ROOT/store'
import Routes from 'ROOT/router'

const { Context } = RootPath

export default ({ appName = '' }) => (
  <ConfigProvider locale={zhCN || {}}>
    <Provider store={store}>
      <Context.Provider value={appName}>
        <HashRouter>
          <Routes />
        </HashRouter>
      </Context.Provider>
    </Provider>
  </ConfigProvider>
)
