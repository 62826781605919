import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import routeConfig from 'ROOT/router/config'
import { Loading, RootPath}  from '@xm/entadmin-main'
import App from 'ROOT/layout/index'

const { Context, Consumer } = RootPath
const defaultRoute = routeConfig.filter(x => x.isIndex)[0]

@Consumer(Context)
class RouteApp extends Component {
  render() {
    const { getPath, context } = this.props

    return (
      <Switch>
        <App>
          <Route
            exact
            path={getPath('activity-type-manage')}
            component={Loadable({
              loader: () => import('ROOT/view/activity-type-manage'),
              loading: () => <Loading />,
            })}
          />
          <Route
            exact
            path={getPath('activity-type-editor/:id?')}
            component={Loadable({
              loader: () => import('ROOT/view/activity-type-editor'),
              loading: () => <Loading />,
            })}
          />
          <Route exact path={getPath()} component={() => <Redirect to={getPath(defaultRoute.path)} />} />
        </App>
      </Switch>
    )
  }
}

export default RouteApp