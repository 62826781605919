/**
 * 处理action中payload为函数的情况，直接运行函数
 */
const funActionThunk = ({ dispatch, getState }) => next => action => {
  if (typeof action.payload === 'function') {
    return action.payload(dispatch, getState)
  }
  return next(action)
}

export default funActionThunk
